.agb-v1 {
	margin-top: var(--page-margin-top);
	padding-top: var(--page-padding-top);

	.static-v1 {
		margin-top: 0;

		.container.my-4 {
			margin-top: 0 !important;
			padding: 0 !important;
		}
	}
}