.static-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		&.umgebung {
			background-image: url(RESOURCE/img/umgebung_hero.webp);
		}
	}

	span {
		font-size: 1.125rem;
	}

	a {
		color: var(--color-secondary);
		word-wrap: break-word;
	}

	ul {
		font-size: 1.125rem;

		&.number-li {
			list-style: auto;
			margin-left: 15px;

			li {
				padding-left: 5px;
			}
		}

		&.roman-li {
			list-style: lower-roman;
			margin-left: 15px;

			li {
				padding-left: 5px;
			}
		}

		&.abc-li {
			li {
				margin-bottom: 5px;
			}
		}

		&.li-disc {
			list-style: disc;
			margin-left: 15px;

			li {
				padding-left: 5px;
			}
		}
	}
}